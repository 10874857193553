.input-container,
.select-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}
.input-container input::placeholder {
  color: var(--black);
}
.calendly-badge-content {
  display: flex !important;
}
.calendly-badge-content,
.calendly-badge-content * {
  font-family: degular-display, sans-serif !important;
  height: auto !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
  font-size: 1.2rem !important;
  color: var(--dark-blue) !important;
  font-weight: 400 !important;
  font-size: 1.05rem !important;
  line-height: 125% !important;
  letter-spacing: 0.5px !important;
}
.calendly-badge-content,
.input-container input,
.select-container select {
  width: 100% !important;
  padding: 15px 30px !important;
  border-radius: 20px !important;
  background: linear-gradient(
    240deg,
    var(--gradient-pale-blue-transparent),
    var(--gradient-pale-blue)
  ) !important;
  border: none !important;
  color: var(--black) !important;
  transition: --gradient-pale-blue-transparent 0.5s !important;
}
input:focus,
select:focus,
.calendly-badge-content:hover {
  --gradient-pale-blue-transparent: rgba(205, 215, 248, 1) !important;
  outline: none !important;
}
.input-container label {
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.2rem;
  color: var(--dark-blue);
}
.error-container {
  background: linear-gradient(90deg, var(--red-gradient));
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 5px 15px;
  position: absolute;
  font-size: 0.8rem;
  top: 100%;
  left: 50px;
  max-width: 0;
  overflow: hidden;
  padding: 5px 0;
  transition: max-width 0.5s, padding-left 0.3s, padding-right 0.3s;
}
.error-container.opened {
  max-width: 200px;
  padding: 5px 15px;
  /* max-height: 27px; */
}
