* {
  font-family: degular-display, sans-serif;
  font-weight: 400;
  font-size: 1.05rem;
  line-height: 125%; /* 21.25px */
  letter-spacing: 0.5px;
}
h1 {
  color: var(--dark-blue);
  font-weight: 500;
  margin: 80px 0 20px;
  line-height: 110%;
  font-size: 3.8rem;
}
h2 {
  margin-bottom: 25px;
  color: var(--dark-blue);
  font-weight: 500;
  line-height: 1.2;
  font-size: 2.9rem;
}
h3 {
  color: var(--dark-blue);
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.5px;
  font-size: 1.8rem;
}
h4 {
  color: var(--dark-blue);
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.2rem;
}
