.convincing-card-container,
.convincing-card {
  position: relative;
  aspect-ratio: 1/0.95;
  max-width: calc(33% - 20px) !important;
}
.convincing-card-container .convincing-card {
  max-width: 100% !important;
  transition: transform 1.5s;
  backface-visibility: hidden;
}
.convincing-card-container::before {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background: linear-gradient(62deg, rgba(205, 215, 248, 0.1) -7.58%, #cdd7f8 127.92%), var(--white);
  transform: rotateY(180deg);
  transition: transform 1.5s;
  backface-visibility: hidden;
}
.convincing-card-container::after {
  content: attr(bottom-content);
  position: absolute;
  max-width: calc(100% - 70px);
  max-height: calc(100% - 70px);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: fit-content;
  transform: rotateY(180deg);
  transition: transform 1.5s;
  backface-visibility: hidden;
}
.convincing-card-container:hover .convincing-card {
  transform: rotateY(180deg);
}
.convincing-card-container:hover::after,
.convincing-card-container:hover::before {
  transform: rotateY(0deg);
}
.mobile-content {
  display: none;
}

@media (max-width: 992px) {
  .convincing-card-container, .convincing-card {
    aspect-ratio: unset;
    height: 100%;
  }
  .convincing-card-container:hover .convincing-card {
    transform: unset;
  }
  .convincing-card-container::after,
  .convincing-card-container::after {
    content: unset;
  }
  .mobile-content {
    display: inline;
  }
  .convincing-card p:first-of-type {
    font-style: italic;
    margin: -15px 0 10px;
  }
  .convincing-card p {
    position: static;
  }
}
