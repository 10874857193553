.card-container {
  padding: 35px;
  border-radius: 30px;
  background: linear-gradient(242deg, rgba(205, 215, 248, 0.1) -7.58%, #cdd7f8 127.92%),
    var(--white);
  height: fit-content;
}
.card-container h3 {
  margin: 10px 0 40px;
}
