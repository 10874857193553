.description-container section:not(:first-child) {
  padding: 100px 0;
}
.description-container section:not(:first-child) .page-container,
.description-container section:not(:first-child).page-container {
  margin: 0 auto;
}
.description-container p:not(:first-child) {
  margin-top: 20px;
}
.description-container section:not(:first-child) .page-container {
  margin: 0 auto;
}
.description-hero h1 {
  margin-bottom: 80px;
}
.description-hero img {
  margin: auto;
  width: 55vw;
  display: block;
  max-height: 700px;
  margin-bottom: 60px;
  object-fit: contain;
}
.description-hero p {
  text-align: center;
  width: 55vw;
  margin: auto;
}
.description-legitimite {
  background-color: var(--grey);
}
.description-legitimite img {
  width: 50%;
}
.description-legitimite .page-container div {
  width: 27vw;
}
.description-legitimite .page-container,
.description-analyse,
.description-solution .page-container {
  display: flex;
  gap: 30px;
}
.description-solution {
  background-color: var(--pale-blue);
}
.description-solution .gradient-button {
  margin-top: 30px;
}
.description-solution .page-container > img {
  height: 100%;
  margin: auto;
}

@media (max-width: 1200px) {
  .description-legitimite img,
  .description-analyse img {
    margin: auto;
    max-height: 300px !important;
    max-width: 100% !important;
  }
  .description-legitimite .page-container,
  .description-analyse {
    flex-direction: column;
  }
  .description-analyse img {
    max-height: calc(100% - 100px);
  }
  .description-container section:not(:first-child) {
    padding: 50px 0;
  }
  .description-legitimite .page-container div,
  .description-analyse div {
    width: auto;
    position: relative;
    z-index: 2;
  }
  .page-container {
    position: relative;
  }
}

@media (max-width: 992px) {
  .description-solution .page-container > img {
    display: none;
  }
  .description-hero img {
    margin: 0 auto;
  }
}
@media (max-width: 768px) {
  .description-hero * {
    width: 100% !important;
  }
}
@media (max-width: 576px) {
}
