.contact-container {
  margin-top: 70px;
  gap: 30px;
  display: flex;
}
.contact-img {
  height: fit-content;
  margin: auto;
}
.contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.contact-form .dark-button {
  margin-left: auto;
}
.calendly-badge-widget {
  position: relative !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 50% !important;
}
.align-center {
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 992px) {
  .contact-container h2 {
    z-index: 2;
  }
  .contact-img {
    display: none;
  }
}
