.select-container {
  gap: 15px;
}
.select {
  position: relative;
}
.select span,
.select span {
  content: "";
  width: 8px;
  height: 10px;
  background-color: var(--dark-blue);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  position: absolute;
  right: 20px;
  bottom: 13px;
}
.select span:last-child {
    bottom: unset;
    top: 13px;
    transform: rotate(180deg);
}
select::-ms-expand {
  display: none;
}
select {
  appearance: none;
}
