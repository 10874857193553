* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: var(--white);
}
a {
  text-decoration: none;
}
.bold {
  font-weight: 600;
}
.text-container  h2{
  margin-top: 30px;
}
.text-container {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.text-container ul {
  margin-left: 30px;
}
.underline {
  text-decoration: underline;
}
.page-container {
  width: var(--container-width);
  margin: auto;
  margin-bottom: 100px;
}
.gradient-button {
  display: inline-block;
  color: var(--dark-blue);
  text-decoration: none;
  background: linear-gradient(90deg, var(--gradient-blue), var(--gradient-transparent));
  border-radius: 30px;
  padding: 10px 30px;
  font-weight: 500;
  cursor: pointer;
  transition: --gradient-blue 0.5s, --gradient-transparent 0.5s;
}
.gradient-button:hover,
.gradient-button:focus {
  outline: none;
  --gradient-transparent: rgba(120, 147, 244, 1);
  --gradient-blue: rgba(120, 147, 244, 0);
}
.dark-button,
.light-button {
  display: inline-block;
  background-color: var(--dark-blue);
  width: fit-content;
  padding: 11px 15px;
  border-radius: 10px;
  color: var(--white);
  border: none;
  cursor: pointer;
  transition: background-color 0.5s, color 0.5s;
}
.light-button,
.dark-button:hover,
.dark-button:focus {
  outline: none;
  background-color: var(--pale-blue);
  color: var(--dark-blue);
}
.light-button:hover,
.light-button:focus {
  outline: none;
  background-color: var(--dark-blue);
  color: var(--pale-blue);
}

.row-1000 {
  display: flex;
  gap: 30px;
}
.hero-section {
  display: flex;
  gap: 50px;
  margin-bottom: 100px;
}
.hero-section div {
  max-width: 33%;
}
.hero-section img {
  margin-top: 80px;
  height: fit-content;
  width: 66%;
}
.prevent-scroll {
  overflow: hidden;
  padding-right: var(--scrollbar-width);
}
body {
  --container-width: 1500px;
}

@media (max-width: 1200px) {
  .row-1000 {
    flex-direction: column;
  }
}

@media (max-width: 1600px) {
  body {
    --container-width: 1320px;
  }
}
@media (max-width: 1400px) {
  body {
    --container-width: 1140px;
  }
}
@media (max-width: 1200px) {
  body {
    --container-width: 920px;
  }
}
@media (max-width: 992px) {
  body {
    --container-width: 700px;
  }
}
@media (max-width: 768px) {
  body {
    --container-width: 500px;
  }
}
@media (max-width: 576px) {
  body {
    --container-width: calc(100% - 40px);
  }
}
