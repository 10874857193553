.home-container section:not(:first-child) {
  padding: 100px 0;
}
.home-container section:not(:first-child) .page-container,
.home-container section:not(:first-child).page-container {
  margin: 0 auto;
}
.home-hero .gradient-button {
  margin-top: 30px;
}
.home-hero {
  position: relative;
  display: flex;
  gap: 50px;
  height: calc(100vh - 204px);
}
.home-hero img {
  position: absolute;
  top: 80px;
  right: 0;
  width: 75%;
}
.home-hero p {
  max-width: 33%;
}
.home-support,
.home-method {
  background-color: var(--pale-blue);
  position: relative;
}
.home-support h2,
.home-support p,
.home-support img {
  max-width: 55vw;
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
}
.home-support img {
  margin-bottom: 60px;
}
.home-convincing {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}
.convincing-card p {
  position: absolute;
  bottom: 35px;
  left: 35px;
  width: calc(100% - 70px);
}
.convincing-title,
.convincing-placeholder {
  width: calc(33% - 20px);
}
.home-solutions {
  background-color: var(--grey);
}
.home-solutions h2,
.home-solutions p,
.home-method h2,
.home-method p {
  text-align: center;
}
.home-solutions-container {
  margin-top: 50px;
  padding: 50px;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
}
.customers-subtitle {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 120%;
  color: var(--dark-blue);
  letter-spacing: 0.8px;
}
.home-customers-content h2 {
  transition: opacity 0.5s;
}
.fade-in {
  opacity: 1;
}
.fade-out {
  opacity: 0;
}
.home-customers-content {
  position: relative;
  width: 100%;
}
.customers-description {
  max-width: 450px;
  position: absolute;
  bottom: 0;
}
.blinking-cursor {
  width: 4px;
  height: 45px;
  top: 8px;
  background-color: var(--dark-blue);
  position: relative;
  margin-left: 10px;
  animation: blink 0.8s step-end infinite;
  display: inline-block;
  transition: opacity 0.2s;
  border-radius: 2px;
}
.blinking-cursor::before,
.blinking-cursor::after {
  border-radius: 2px;
  display: none;
  content: "";
  width: 18px;
  left: -7px;

  height: 4px;
  background-color: var(--dark-blue);
  position: absolute;
  top: 0;
}
.blinking-cursor::after {
  top: unset;
  bottom: 0;
}
.home-method {
  position: relative;
  padding-bottom: 0 !important;
}
.home-method-header {
  background-color: var(--pale-blue);
  z-index: 5;
  height: 165px;
  position: absolute;
  top: 100px;
  margin: auto;
  left: 0;
  right: 0;
}
.home-method-header::before {
  content: "";
  width: 100%;
  height: 130px;
  position: absolute;
  top: 100%;
  background: linear-gradient(180deg, rgba(205, 215, 248, 1), rgba(205, 215, 248, 0));
}
.home-method-timeline-container {
  position: relative;
}
.home-method::before {
  content: "";
  width: 100%;
  height: 130px;
  position: absolute;
  bottom: 0;
  background: linear-gradient(0deg, rgba(205, 215, 248, 1), rgba(205, 215, 248, 0));
  z-index: 2;
}
.home-method-timeline-container::-webkit-scrollbar {
  width: 0px;
}
.home-method-timeline-container {
  padding-top: 165px;
  position: relative;
  overflow-y: scroll;
  max-height: calc(130vh);
}
.home-quote .page-container,
.home-expertise,
.home-customers {
  display: flex;
  gap: 30px;
}
.home-expertise div {
  max-width: 40%;
  margin-right: 150px;
}
.home-expertise img {
  width: 100%;
}
.home-expertise ul {
  list-style-type: square;
}
.home-expertise li {
  margin-top: 30px;
}
.home-values {
  background-color: var(--grey);
}
.home-values h2 {
  max-width: 420px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.home-values-container {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px 10vw;
}
.value-card {
  text-align: center;
  font-weight: 500;
  padding: 20px 40px;
  background-color: var(--white);
  color: var(--dark-blue);
  border-radius: 60px;
  font-size: 1.2rem;
  transition: color 0.5s, background-color 0.5s;
}
.value-card:hover {
  color: var(--white);
  background-color: var(--dark-blue);
}
.home-quote {
  background-color: var(--pale-blue);
}
.home-quote .page-container {
  justify-content: space-between;
}
.quote-container {
  margin-top: auto;
  max-width: 50%;
  margin-left: auto;
}
.quote-container img {
  margin-bottom: 70px;
  display: block;
  width: 70px;
}
.quote-container * {
  color: var(--dark-blue);
}
.quote-container p {
  font-size: 1.8rem;
}
.quote-author {
  margin-top: 15px;
  margin-left: auto;
  margin-right: 40px;
  width: fit-content;
  font-size: 1.2rem;
}
.home-stories {
  background-color: var(--grey);
}
.stories-container {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
}
.story-container {
  position: relative;
  background-color: var(--white);
  padding: 38px;
  border-radius: 30px;
  width: calc(50% - 15px);
  min-height: 260px;
}
.story-container img {
  width: 38px;
  margin-bottom: 10px;
}
.story-container h3 {
  margin-bottom: 10px;
}
.story-container p {
  white-space: break-spaces;
  display: block;
  margin-top: auto;
}
.story-logo {
  position: absolute;
  height: 50px;
  max-width: calc(100% - 114px);
  top: 70px;
  right: 38px;
  width: auto !important;
}
.home-methode-header,
.home-method .page-container {
  position: relative;
}
.home-methode-header:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 50px;
  background: linear-gradient(var(--dark-blue), transparent);
  left: 0;
  top: 100%;
}
.timeline-item-container .card-container:hover {
  transform: scale(1.05);
}
.timeline-item-container .card-container {
  transition: transform 0.5s;
}
.clients-container {
  display: flex;
  justify-content: space-evenly;
  gap: 15px;
  margin-bottom: 40px;
  margin-top: 40px;
}
.clients-container img {
  height: 40px;
}

@keyframes blink {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@media (max-width: 1200px) {
  .home-hero {
    height: auto;
  }
  .home-hero img {
    bottom: 0;
    top: unset;
  }
  .convincing-title {
    width: 100%;
  }
  .convincing-placeholder {
    display: none;
  }
  .home-container .convincing-card-container {
    max-width: calc(50% - 15px) !important;
  }
  .home-customers,
  .home-expertise,
  .home-quote .page-container {
    flex-direction: column;
  }
  .home-values-container {
    padding: 40px 0;
  }
  .home-customers img {
    max-height: 300px;
    object-fit: contain;
  }
  .home-customers h2 {
    height: 120px;
    margin-bottom: 30px;
  }
  .home-expertise div,
  .quote-container {
    max-width: 100%;
    margin: 0;
  }
  .customers-description {
    position: relative;
    max-width: 100%;
  }
  .story-container {
    width: calc(50% - 15px);
  }
  .clients-container img {
    height: 25px;
  }
  .story-container img {
    top: 30px;
    max-height: 40px;
  }
}

@media (max-width: 992px) {
  .convincing-card {
    aspect-ratio: unset;
    height: 400px;
  }
}

@media (max-width: 768px) {
  .home-container .convincing-card-container {
    max-width: 100% !important;
  }
  .home-hero {
    flex-direction: column;
    gap: 0;
  }
  .home-hero img {
    position: static;
    width: 100%;
    margin: auto;
  }
  .home-hero p,
  .home-support h2,
  .home-support p,
  .home-support img {
    max-width: 100%;
  }
  .home-customers h2 {
    height: 180px;
  }
  .story-container {
    width: 100%;
  }
}
@media (max-width: 568px) {
  .home-method-header {
    height: 223px;
  }
  .clients-container {
    flex-wrap: wrap;
  }
  .clients-container img {
    height: 20px;
  }
  .story-container img {
    max-height: 30px;
  }
}
