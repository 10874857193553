.timeline-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 150px auto 250px;
}
.timeline-container::before,
.timeline-container::after {
  content: "";
  position: absolute;
  height: calc(100% - 100px);
  border-color: var(--dark-blue);
  border-width: 1px;
}
.timeline-container::before {
  top: 0;
  left: 75px;
  width: calc(50% - 75px);
  border-top-style: solid;
  border-right-style: solid;
  border-top-right-radius: 30px;
}
.timeline-container::after {
  bottom: 50px;
  right: calc(50% - 76px);
  width: 75px;
  border-bottom-style: solid;
  border-left-style: solid;
  border-bottom-left-radius: 30px;
}
.timeline-arrows {
  display: flex;
  gap: 10px;
  flex-direction: column;
  position: absolute;
  bottom: -100vh;
  right: 50px;
}
.timeline-arrow:first-child {
  transform: rotate(180deg);
}
.timeline-arrow:first-child:hover {
  transform: scale(1.2) rotate(180deg);
}
.timeline-arrow:hover {
  transform: scale(1.2);
}
.timeline-arrow {
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
  border: none;
  background-color: var(--dark-blue);
  height: 40px;
  width: 25px;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}
.timeline-arrow.visible {
  cursor: pointer;
  opacity: 1;
}

@media (max-width: 768px) {
  .timeline-container::before {
    transform: rotateY(180deg);
    width: 70px !important;
  }
  .timeline-container::before,
  .timeline-container::after {
    left: 0;
  }
  .timeline-container {
    padding-bottom: 110px;
  }
}
