.footer-container {
  background-color: var(--dark-blue);
}
footer {
  height: 200px;
  padding: 50px 0;
  margin: auto;
  width: var(--container-width);
  display: flex;
  justify-content: space-between;
}
.footer-logo {
  height: 100%;
}
footer * {
  color: var(--white);
}
.footer-nav,
.footer-contact {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.footer-nav div {
  display: flex;
  gap: 40px;
}
.footer-contact div {
  display: flex;
  gap: 30px;
  justify-content: flex-end;
  width: 100%;
}
.footer-contact a {
  text-decoration: underline;
}
.footer-nav,
.footer-contact a {
  font-size: 0.9rem;
}

@media (max-width: 1200px) {
  .footer-logo {
    display: none;
  }
}

@media(max-width: 992px) {
    footer {
        flex-direction: column-reverse;
        gap: 30px;
        height: auto;
    }
    .footer-nav, .footer-contact {
        gap: 30px;
    }
    .footer-contact div {
        justify-content: center;
    }
}
