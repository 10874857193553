nav {
  width: var(--container-width);
  margin: auto;
  padding: 25px 0;
  display: flex;
  justify-content: space-between;
}
nav img:hover {
  transform: scale(1.05);
}
nav img {
  transition: transform 0.5s;
  height: 50px;
  object-fit: contain;
}
.header-links {
  display: flex;
  align-items: center;
  gap: 40px;
}
.header-links a {
  color: var(--dark-blue);
  font-weight: 500;
  position: relative;
}
.header-links a:not(.gradient-button):before {
  background: var(--dark-blue);
  content: "";
  position: absolute;
  bottom: -2px;
  height: 2px;
  border-radius: 2px;
  width: 0;
  transition: width 0.3s;
  left: 0;
}
.header-links a:not(.gradient-button):hover::before {
  width: 100%;
}

@media (max-width: 992px) {
  .header-links {
    position: relative;
  }
  .header-links .gradient-button {
    position: absolute;
    top: 100%;
    right: 0;
    width: max-content;
  }
}
@media(max-width: 568px) {
  .header-links {
    gap: 10px;
  }
}
