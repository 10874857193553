.solution-container {
  padding: 20px 40px;
  border-radius: 80px;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  width: fit-content;
  align-items: center;
  transition: background-color 0.5s;
  min-width: 240px;
  cursor: pointer;
}
.solution-container:hover {
  background-color: var(--dark-blue);
}
.solution-title,
.solution-button {
  text-align: center;
  color: var(--dark-blue);
  opacity: 1;
  transition: opacity 0.3s;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.8px;
  transition: opacity 0.5s;
}
.solution-button {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 15px;
  opacity: 0;
  color: var(--white);
}
.solution-container:hover .solution-title {
  opacity: 0;
}
.solution-container:hover .solution-button {
  opacity: 1;
}

@media (max-width: 992px) {
  .solution-button {
    position: relative;
    opacity: 1;
    text-decoration: underline;
  }
  .solution-container {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    width: 100%;
    background-color: var(--dark-blue);
  }
  .solution-title {
    color: var(--white);
  }
  .solution-container:hover {
    background-color: var(--pale-blue);
  }
  .solution-container:hover .solution-title {
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .solution-container {
    flex-direction: column;
    gap: 5px;
  }
}
