.solution-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}
.solutions-container {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}
.solution-description span {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 24px;
}
.solution-description p {
    color: var(--dark-blue);
    white-space: pre-line;
}
.solution-description {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.solution-extra {
    max-height: 0;
    transition: max-height 0.8s;
    overflow: hidden;
}
.solution-extra.expanded {
    max-height: 600px;
}
.solutions-container .card-container {
    max-width: calc(33% - 17px) !important;
}

@media(max-width: 1200px) {
    .solutions-container .card-container {
        max-width: calc(50% - 15px) !important;
    }
}

@media (max-width: 992px) {
    .solutions-page-container .hero-section > * {
        max-width: 100%;
    }
    .solutions-page-container .hero-section img {
        margin: 0 auto;
    }
    .solutions-page-container .hero-section {
        flex-direction: column;
    }
}

@media(max-width: 768px) {
    .solutions-container .card-container {
        max-width: 100% !important;
    }
}

