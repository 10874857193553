.timeline-item-container {
    width: 50%;
    position: relative;
}
.timeline-item-container.with-line {
    margin-top: -50px;
}
.timeline-item-container:last-child {
    position: absolute;
    top: calc(100% - 50px);
}
.timeline-item-container.align-left {
    padding-right: 30px;
}
.timeline-item-container.align-right {
    left: 50%;
    padding-left: 60px;
}
.timeline-item-container img {
    position: relative;
    top: -15px;
    left: 35px;
}
.timeline-item-container h3 {
    position: relative;
    left: 35px;
    margin-top: -5px;
    margin-bottom: 30px;
    max-width: calc(100% - 35px);
}
.timeline-item-container .timeline-line {
    top: 0;
    position: absolute;
    height: 1px;
    background-color: var(--dark-blue);
}
.timeline-item-container.align-left .timeline-line {
    width: calc(100% - 75px);
    right: 0;
}
.timeline-item-container.align-right .timeline-line {
    width: 75px;
    left: 0;
}

@media (max-width: 768px) {
    .timeline-item-container {
        padding: 0 0 0 50px !important;
        width: 100%;
        left: 0 !important;
    }
    .timeline-item-container.with-line {
        margin-top: 60px;
    }
    .timeline-item-container .timeline-line {
        width: 70px !important;
        left: 0;
    }
    .timeline-item-container:last-child {
      top: calc(100% - 50px);
    }
}