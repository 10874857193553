body {
  --black: #101010;
  --dark-blue: #000d84;
  --pale-blue: rgb(205, 215, 248);
  --grey: #eceff9;
  --white: #fafafa;
  --gradient: rgba(120, 147, 244, 1), rgba(120, 147, 244, 0);
  --red-gradient: rgb(243, 105, 105) 0%, rgb(244, 120, 120) 10%, rgba(120, 147, 244, 0) 100%;
}
@property --gradient-blue {
  syntax: "<color>";
  initial-value: rgba(120, 147, 244, 1);
  inherits: false;
}
@property --gradient-transparent {
  syntax: "<color>";
  initial-value: rgba(120, 147, 244, 0);
  inherits: false;
}
@property --gradient-pale-blue {
  syntax: "<color>";
  initial-value: rgba(205, 215, 248, 1);
  inherits: false;
}
@property --gradient-pale-blue-transparent {
  syntax: "<color>";
  initial-value: rgba(205, 215, 248, 0.1);
  inherits: false;
}
